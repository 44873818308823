import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
import './assets/base.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'jquery/dist/jquery.min.js'
import 'animate.css/animate.min.css'
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)
Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$wow = wow
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
