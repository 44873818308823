<template>
<div class="btm_footer">
        <div class="content" v-html="copyright.tag">
<!--            <p>smartLinks.com 版权所有</p>-->
<!--            <p>公安备案：12312412</p>-->
<!--            <p>IC备案f1231号</p>-->
        </div>
    </div>
</template>

<script>
export default {
  name : "myFFooter",
  props:{
    copyright : Object,
  },
  methods:{

  }

}
</script>

<style lang="scss">
div.btm_footer {
    background-color: #0E0E14;
    div.content {
        color: #999;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
}

</style>