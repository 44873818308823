<template>
   <!-- footer -->
    <div class="ind_footer">
        <div class="footer_con d-flex">
            <div class="footer_nav">
                <div class="nav_item" v-for="(item,index) in broordMenuParentList">
                  <div class="nav_tit">{{item.tag}}</div>
                  <div class="nav_list d-flex">
                      <ul v-for="temp in handList(item.children)">
                        <li v-for="temp2 in temp">
                          <a @click = "gotoUrl(temp2.url)" style="color: white;text-decoration: none;">{{temp2.tag}}</a>
                        </li>
                      </ul>
                  </div>
                </div>
<!--                <div class="nav_item">-->
<!--                    <div class="nav_tit">业务领域</div>-->
<!--                    <div class="nav_list d-flex">-->
<!--                        <ul>-->
<!--                          <li><a href="https://www.baidu.com/" style="color: white;text-decoration: none;">建筑设计</a></li>-->
<!--                            <li>装修设计</li>-->
<!--                            <li>园林设计</li>-->
<!--                            <li>景观设计</li>-->
<!--                        </ul>-->
<!--                        <ul>-->
<!--                            <li>智慧园林</li>-->
<!--                            <li>智慧生活</li>-->
<!--                            <li>技术咨询</li>-->
<!--                            <li>可视化设计</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="nav_item">-->
<!--                    <div class="nav_tit">新闻中心</div>-->
<!--                    <div class="nav_list d-flex">-->
<!--                        <ul>-->
<!--                            <li>公司新闻</li>-->
<!--                            <li>行业动态</li>-->
<!--                            <li>政策资讯</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="nav_item">-->
<!--                    <div class="nav_tit">公司简介</div>-->
<!--                    <div class="nav_list d-flex">-->
<!--                        <ul>-->
<!--                            <li>企业文化</li>-->
<!--                            <li>荣誉资质</li>-->
<!--                            <li>关于我们</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="nav_item">-->
<!--                    <div class="nav_tit">联系我们</div>-->
<!--                    <div class="nav_list d-flex">-->
<!--                        <ul>-->
<!--                            <li>招聘信息</li>-->
<!--                            <li>联系我们</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="nav_item">-->
<!--                    <div class="nav_tit">友情链接</div>-->
<!--                    <div class="nav_list d-flex">-->
<!--                        <ul>-->
<!--                            <li>广东智联蔚来科技有限公司</li>-->
<!--                            <li>广州柏信建材有限公司</li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div class="foot_hr"></div>
            <div class="wechat">
                <div class="wachat_t">{{ wechat.tag }}</div>
                <img class="code" :src="handUrl(wechat.icon)" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import baseUrl from '@/utils/global'
export default {
  name : "myFFooter",
  props:{
    wechat : Object,
    broordMenuParentList : Array
  },
  methods:{
    handUrl(url){
      return baseUrl + url;
    },
    handList(list){
      const result = [];

      for (let i = 0; i < list.length; i += 4) {
        result.push(list.slice(i, i + 4));
      }
      return result;
    },
   gotoUrl(url){
    if(url == null || url == undefined || url == ""){
      return;
    }
    // 获取当前页面的URL
    const currentUrl = window.location.href;
    // 创建一个新的URL对象
    const absoluteUrl = new URL(url, currentUrl);
    // 跳转到绝对路径
    window.location.href = absoluteUrl.toString();
   }

  }

}
</script>

<style lang="scss">
*{
  padding: 0;
}
// footer
div.ind_footer {
    background-color: #212121;
    height: auto;
    overflow: hidden;

    div.footer_con {
        width: 1200px;
        margin: 32px auto;
        justify-content: space-around;
    }

    div.footer_nav {
        display: flex;
        justify-content: left;

        div.nav_item {
            padding-left: 40px;

            div.nav_tit {
                color: #fff;
                font-size: 14px;
                margin-bottom: 20px;
            }

            div.nav_list {
                color: #fff;
                opacity: 0.6;
                font-size: 12px;

                ul {
                    margin-right: 20px;

                    li {
                        line-height: 24px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    div.foot_hr {
        height: 180px;
        background-color: #fff;
        width: 1px;
        opacity: 0.8;
    }

    div.wechat {
        div.wachat_t {
            color: #fff;
            font-size: 18px;
            margin-bottom: 20px;
        }

        img.code {
            width: 124px;
            height: 124px;
            background-color: #fff;
        }
    }
}
</style>
