<template>
  <!-- 导航 -->
  <nav class="navbar" role="navigation">
    <div class="container-fluid d-flex">
      <!--LOGO-->
      <div class="navbar-logo">
        <router-link class="logo" :to="handleUrl(getFirstElement(list).url)">{{getFirstElement(list).tag}}</router-link>
      </div>
      <!--导航栏-->
      <div class="navbar-item d-flex">
        <ul class="nav justify-content-center">
          <li class="nav-item" v-for="item in getSubArray(list)">
            <router-link
              :to="handleUrl(item.url)"
              class="nav-link"
              @click="clickStyle(item)"
            >{{ item.tag }}</router-link>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link active" href="index.html">首 页</a>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <router-link to="/Product" class="nav-link">项目作品</router-link>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <router-link to="/About" class="nav-link">公司简介</router-link>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <router-link to="/Server" class="nav-link">业务领域</router-link>-->
          <!--          </li>-->

          <li v-for="(item, index) in list" :key="item.id" @click="activeIndex = index">
        <a :class="{ active: index === activeIndex }" href="#">{{ item.name }}</a>

          <li class="nav-item" @click="handleClick">
            <a href="#my-anchor2" ref="myAnchor2">
              <div class="nav-link">{{ getNew(list).tag }}</div>
            </a>
          </li>
        </ul>
      </div>
      <div class="navbar-end">
        <router-link
          :to="handleUrl(getLastElement(list).url)"
          class="contact"
        >{{getLastElement(list).tag}}</router-link>
      </div>
    </div>
    <!-- /.container-fluid -->
  </nav>
</template>


<script>
export default {
  props: {
    list: Array
  },
  methods: {
    getFirstElement(list) {
      return list[0];
    },
    getLastElement(list) {
      return list[list.length - 1];
    },
    getNew(list) {
      return list[list.length - 2];
    },
    getSubArray(list) {
      return list.slice(1, list.length - 2);
    },
    handleUrl(url) {
      if (url == null || url == "" || url == undefined) {
        return "";
      }
      return url;
    },
    handleClick() {
      // console.log('aa :>> ', document.querySelector('#my-anchor2'));
      // document.querySelector('#my-anchor2').scrollIntoView({
      //   behavior: "smooth"
      // });
      if (
        window.location.hash === "#/my-anchor2" ||
        window.location.hash === "#/About"
      ) {
        return;
      }
      this.$router.push({ path: "/About", query: { dot: "1" } });
    }
  },
  mounted() {
    // 获取锚点元素
    const anchor = this.$refs.myAnchor2;
    console.log(anchor); // 输出锚点元素
  }
};
</script>

<style>
.container-fluid {
  width: 1600px;
}
/* index.strat */
/* 导航 */
.navbar {
  background: rgba(13, 14, 19, 0.48);
  height: 133px;
  z-index: 999;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar-logo {
  color: #fff;
  font-size: 43px;
}
.navbar-logo .logo {
  color: #fff;
  text-decoration: none;
}
.navbar-item .nav-item .nav-link {
  color: rgb(255 255 255 / 68%);
  font-size: 18px;
  transition: 0.3s;
}
.navbar-item .nav-item .active {
  color: #fff;
  font-size: 18px;
}
.navbar-item .nav-item:hover .nav-link {
  color: #fff;
  font-size: 18px;
}
.navbar-end .contact {
  color: #fff;
  font-size: 24px;
}
</style>
