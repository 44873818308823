import axios from 'axios';
import baseUrl from './global'
//通用的request方法

// 创建axios实例
const service = axios.create({   //通过调用axios.create()方法创建了一个名为service的axios实例。
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: baseUrl,
    // 超时
    timeout: 10000  //限制axios请求的最长等待时间。如果请求在10秒内没有响应，那么请求将被自动取消，并抛出一个错误。这样可以防止程序长时间等待无响应的请求，提高程序的性能和稳定性。
})

export default service
