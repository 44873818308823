<template>
  <!-- 轮播图 -->
  <div class="banner">
    <img :src="handleUrl(getBanner(imgList).url)" style="width: 1920px;height: 1080px" alt />
  </div>
</template>

<script>
import baseUrl from '@/utils/global'
export default
{
    props:{
      imgList : Array
    },
  methods:{
      handleUrl(url) {
        return baseUrl+url;
      },
    getBanner(list){
        return list[0];
      }
  }
}
</script>

<style>
/* banner */
.banner {
  max-width: 100vw;
  position: relative;
  margin-top: -133px;
}
</style>
