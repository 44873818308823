<template>
  <div>
    <Navbar :list="topMenuList" @handleClick="handleClick"></Navbar>
    <Banner :img-list="BannerList"></Banner>
    <router-view ></router-view>
    <Footer :wechat="wechat" :broord-menu-parent-list="broordMenuParentList"></Footer>
    <FFooter :copyright="copyright"></FFooter>
  </div>
</template>

<script>
// import Navbar from './components/Navbar'
import Navbar from "@/components/Navbar/myNavbar.vue";
import Banner from "@/components/Banner/myBanner.vue";
import Footer from "@/components/Footer/myFooter.vue";
import FFooter from "@/components/FFooter/myFFooter.vue";
import { bottomMenu, topMenu, carouselBanner,getTdk } from "./api/commom";   
export default {
  components: {
    Navbar,
    Banner,
    Footer,
    FFooter
  },
  metaInfo(){
    return {
      title: this.tdk.title,
      meta: [
        { charset: "utf-8" },
        { name: "keywords", content: this.tdk.keywords },
        { name: "description", content: this.tdk.description },
      ],
  }
  },
  data() {
    return {
      topMenuList: [],
      broordMenuParentList: [],
      wechat: {},
      copyright: {},
      BannerList: [],
      tdk:{}    //?
    };
  },
  methods: {
    initBottomMenu() {
      bottomMenu().then(res => {     //该方法通过调用bottomMenu()函数来获取底部菜单的数据。然后，将返回的结果存储在变量res中。接下来，从res.data中提取出data对象
        let data = res.data.data;
        if (res.data.code == 200) {
          this.wechat = data.wechat;  //将data对象中的wechat、broordMenuParentList和copyright属性分别赋值给当前对象的相应属性。
          this.broordMenuParentList = data.broordMenuParentList;
          this.copyright = data.copyright;
        }
      });
    },
    initTopMenu() {
      topMenu().then(res => {
        let data = res.data.data;
        if (res.data.code == 200) {
          this.topMenuList = data;
        }
      });
    },
    initBanner() {
      carouselBanner().then(res => {
        let data = res.data.data;
        if (res.data.code == 200) {
          this.BannerList = data;
        }
      });
    },
    handleClick(){
      this.$emit('handleClick',"投注站");  //使用$emit方法触发名为handleClick的自定义事件，并将字符串"投注站"作为参数传递给该事件。
    }
  },
  created() {
    this.initBottomMenu();
    this.initTopMenu();
    this.initBanner();
    getTdk().then(res => {
      this.tdk = res.data.data
    })
  }
};
</script>

<style>
</style>
