import request from "../utils/request.js"

//调用轮播图接口
export function carouselBanner(){
    return request({
        url: "/broord/banner/public/carouselBanner",
        method: 'get'
    })
}

//调用底部导航栏接口
export function bottomMenu(){
    return request({
        url: "/broord/menu/public/bottomMenu",  
        method: "get"
    })
}

//调用顶部导航栏接口
export function topMenu(){
    return request({
        url: "/broord/menu/public/topMenu",
        method: "get"
    })
}

//调用tdk接口
export function getTdk () {
  return request({
    url: '/broord/tdk/public/getTdk',
    method: 'get'
  })
}
