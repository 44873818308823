import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      path: '/Index',
      component: () => import('@/pages/Index/myIndex.vue')
    },
    {
      name: 'handleUrl(item.url)',
      path: '/About',
      component: () => import('@/pages/About/myAbout.vue')
    },
    {
      path: '/Product',
      component: () => import('@/pages/Product/myProduct.vue')
    },
    {
      path: '/Productlist',
      component: () => import('@/pages/Productlist/myProductlist.vue')
    },
    {
      path: '/Server',
      component: () => import('@/pages/Server/myServer.vue')
    },
    {
      path: '/Contact',
      component: () => import('@/pages/Contact/myContact.vue')
    },
    {
      path: '/News',
      component: () => import('@/pages/About/News/myNews.vue')
    },
    {
      path: '*',
      redirect: '/Index'
    }
  ]
})
